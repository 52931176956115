import { useEffect, useMemo } from 'react';

import { getShortCurrency } from '../../helpers/numbers/getShortCurrency';
import { roundToFixed } from '../../helpers/numbers/numbers';
import { getAnalyticsPnl } from '../../helpers/pnl/getAnalyticsPnl';
import { useAppSelector } from '../../store/store';

// Component
const DocumentTitle = (): JSX.Element => {
    // Get valuees from store
    const {
        ui: { hideAmounts, title },
        analytic: { analytics },
    } = useAppSelector(state => state);

    // Build metas
    const metas = useMemo(() => {
        const _m = [title];
        const stats = getAnalyticsPnl(analytics);
        if (stats) {
            const pnl = (stats.pnl > 0 ? '+' : '') + roundToFixed(stats.pnl, 1) + '%';
            if (hideAmounts) {
                _m.unshift(pnl);
            } else {
                _m.unshift(`${getShortCurrency(stats.balance)} (${pnl})`);
            }
        }
        return _m;
    }, [hideAmounts, title, analytics]);

    // Update title
    useEffect(() => {
        document.title = metas.join(' | ');
    }, [metas]);

    // Returns fake element
    return <></>;
};

export { DocumentTitle };
