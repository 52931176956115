import { createTheme, PaletteOptions } from '@mui/material';
import shadows, { Shadows } from '@mui/material/styles/shadows';
import { NavLink as RouterLink, NavLinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import React from 'react';

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

// Dark mode
const darkModePalette: PaletteOptions = {
    background: {
        default: '#546e7a',
        paper: '#435862',
    },
    primary: {
        main: '#118ab2',
    },
    secondary: {
        main: '#FFFFFF',
    },
    success: {
        main: '#65C466',
    },
};

export const buildTheme = (mode: 'light' | 'dark') =>
    createTheme({
        typography: {
            fontFamily: `'Nunito', sans-serif`,
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
        },
        palette: {
            mode,
            background: {
                default: '#ffffff',
                paper: '#fafafa',
            },
            primary: {
                main: '#118ab2',
            },
            secondary: {
                main: '#616161',
            },
            success: {
                main: '#65C466',
            },
            contrastThreshold: 3,
            ...(mode === 'dark' ? darkModePalette : {}),
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 810,
                md: 1080,
                lg: 1260,
                xl: 1536,
            },
        },
        shadows: shadows.map(() => 'none') as Shadows,
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                    LinkComponent: LinkBehavior,
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
            },
            MuiLink: {
                defaultProps: {
                    component: LinkBehavior,
                } as LinkProps,
            },

            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark' ? '#4d636f' : '#f9f9f9',
                    },
                },
            },
        },
    });
