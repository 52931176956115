// Initial state of the wallet
const initialState: BlockchainNetworkState = {
    networks: [
        {
            name: 'Ethereum',
            description: 'Mainnet',
            chainId: '0x1',
            symbol: 'ETH',
            icon: '/assets/blockchains/ethereum-eth-logo.svg',
        },
        {
            name: 'BSC',
            description: 'Mainnet',
            chainId: '0x38',
            symbol: 'BNB',
            icon: '/assets/blockchains/binance-coin-bnb-logo.svg',
        },
        {
            name: 'FANTOM',
            description: 'OPERA',
            chainId: '0xfa',
            symbol: 'FTM',
            icon: '/assets/blockchains/fantom-ftm-logo.svg',
        },
        {
            name: 'Polygon',
            description: 'Mainnet',
            chainId: '0x89',
            symbol: 'MATIC',
            icon: '/assets/blockchains/polygon-matic-logo.svg',
        },
    ],
};

// Expose reducer
export function networkReducer(state = initialState, action: ReducerAction) {
    switch (action.type) {
        // No action
        default:
            return state;
    }
}
