import React from 'react';
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LazyLoader from './components/ContentLoader/LazyLoader';
import { loadAccountAction } from './store/actions/accountActions';
import { ThemeTypes, useDarkMode } from './hooks/useDarkMode';
import { FavIcon } from './components/Favicon/Favicon';
import ScrollToTop from './components/Page/ScrollToTop';
import PullToRefresh from 'react-simple-pull-to-refresh';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { loadAnalyticAction } from './store/actions/analyticActions';
import { DocumentTitle } from './components/DocumentTitle/DocumentTitle';
import { buildTheme } from './theme/theme';
import { useAppSelector } from './store/store';
import { useDispatch } from 'react-redux';
import { useWalletConnect } from './hooks/useWalletConnect';
import AuthProvider from './components/AuthProvider/AuthProvider';

// Lazy load pages and components
const ResponsiveAppBar = lazy(() => import('./components/AppBar/AppBar'));
const Home = lazy(() => import('./routes/Home/Home'));
const About = lazy(() => import('./routes/About'));
const AccountsList = lazy(() => import('./routes/Accounts/AccountsList'));
const AccountDetails = lazy(() => import('./routes/Accounts/AccountDetails'));
const AccountCreate = lazy(() => import('./routes/Accounts/AccountCreate'));
const AssetList = lazy(() => import('./routes/Assets/AssetList'));
const AssetDetails = lazy(() => import('./routes/Assets/AssetDetails'));
const OrdersList = lazy(() => import('./routes/Orders/OrdersList'));
const Settings = lazy(() => import('./routes/Settings/Settings'));
const AccountInfo = lazy(() => import('./routes/Accounts/AccountInfo'));

const App = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useWalletConnect();

    // Get theme
    useDarkMode();
    const theme: string = useAppSelector(state => state.ui.theme);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
    const themeUI = React.useMemo(() => {
        const mode = theme === ThemeTypes.THEME_AUTO ? prefersDarkMode : theme === ThemeTypes.THEME_LIGHT ? 'light' : 'dark';
        return buildTheme(mode);
    }, [theme, prefersDarkMode]);

    // Choos favicon
    const favIcon = theme === ThemeTypes.THEME_LIGHT ? '/assets/favicon/favicon.ico' : '/assets/favicon/favicon-dark.ico';

    // Hook : Fetch datas from API to store via thunk
    useEffect(() => {
        const interval = setInterval(() => {
            if (isAuthenticated) {
                dispatch(loadAccountAction());
                dispatch(loadAnalyticAction());
            }
        }, 10000);

        if (isAuthenticated) {
            dispatch(loadAccountAction());
            dispatch(loadAnalyticAction());
            console.log('-- bind refresh');
        }

        return () => clearInterval(interval);
    }, [dispatch, isAuthenticated]);

    // Handle pull refresh
    const handlePullRefresh = (): Promise<void> => {
        return new Promise(async res => {
            console.log('pull refresh');
            await dispatch(loadAccountAction(true));
            await dispatch(loadAnalyticAction(true));
            res();
        });
    };

    // console.log('REACT_APP_VERSION', process.env.REACT_APP_VERSION)

    return (
        <ThemeProvider theme={themeUI}>
            <Suspense fallback={<LazyLoader delay={300} />}>
                <AuthProvider>
                    <CssBaseline />
                    <BrowserRouter>
                        <DocumentTitle />
                        <FavIcon url={favIcon} badgeEnabled={false} badgeColor={'#00bcd4'} badgeCount={'2'} />
                        <ScrollToTop />
                        <ResponsiveAppBar />
                        <Suspense fallback={<LazyLoader delay={10} />}>
                            <PullToRefresh onRefresh={handlePullRefresh}>
                                <Routes>
                                    <Route index element={<Home />} />
                                    <Route path="/assets" element={<AssetList />} />
                                    <Route path="/assets/:symbol" element={<AssetDetails />} />
                                    <Route path="/accounts" element={<AccountsList />} />
                                    <Route path="/accounts/add" element={<AccountCreate />} />
                                    <Route path="/accounts/:accountId" element={<AccountDetails />} />
                                    <Route path="/orders" element={<OrdersList />} />
                                    <Route path="/settings" element={<Settings />} />
                                    <Route path="/account" element={<AccountInfo />} />
                                    <Route path="/about" element={<About />} />
                                </Routes>
                            </PullToRefresh>
                        </Suspense>
                    </BrowserRouter>
                </AuthProvider>
            </Suspense>
        </ThemeProvider>
    );
};

export default App;
