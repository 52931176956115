import { styled, Theme } from '@mui/material';

export enum LOGO_STATUS {
    DISCONNECTED,
    ACTIVE,
    DELAYED,
    OUTDATED,
}

export const StyledLogoAnimated = styled('div')(
    ({ theme, size, animated, status = LOGO_STATUS.DISCONNECTED }: { theme?: Theme; size: number | string; animated?: boolean; status?: LOGO_STATUS }) => {
        let animationPlayState = 'paused';
        let backgroundColor = theme?.palette.grey[500];

        if ([LOGO_STATUS.ACTIVE, LOGO_STATUS.DELAYED].includes(status) || animated === true) {
            animationPlayState = 'running';
        }

        if (status === LOGO_STATUS.ACTIVE) {
            backgroundColor = '#118ab2';
        }

        if (status === LOGO_STATUS.OUTDATED) {
            backgroundColor = theme?.palette.error.main;
        }

        return {
            transition: 'all 400ms ease-in-out',
            width: size,
            height: size,
            background: backgroundColor,
            maskImage: 'url(/logo.svg)',
            maskSize: 'cover',
            animation: 'spin 20s infinite linear',
            animationPlayState: animationPlayState,
            margin: 'auto auto 1rem auto',
        };
    },
);
