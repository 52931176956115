import { useEffect } from 'react';
import { useAppSelector } from '../../store/store';

// Components properties
export type FaviconProps = {
    id?: string;
    url?: string;
    badgeEnabled?: boolean;
    badgeColor?: string;
    badgeCount?: string;
    animated?: boolean;
};

// Register the current favicon url (and watch for changes)
let currentUrl: string = '';
let currentCount: string = '';
const favIconSize = 16;
const favIconBadgeSize = favIconSize / 3;

// Component
const FavIcon = (props: FaviconProps) => {
    // Get Colors from UI store
    const title = useAppSelector(state => state.ui.title);

    // Props defaults
    const id = props.id || 'favicon';
    const url = props.url || '/favicon.ico';
    const badgeEnabled = props.badgeEnabled || true;
    const badgeColor = props.badgeColor || 'rgba(255, 0, 0, 1)';
    const badgeCount = props.badgeCount || '0';
    // const animated = props.animated || false;

    // Find favicon DOM
    const searchFavIconDOM = () => {
        // DOM is already known
        let el: any = document.querySelector(`link#${id}`) || document.querySelector(`link[rel="icon"][href$="favicon.ico"]`);

        // Create DOM if not exists
        if (!el) {
            const head = document.getElementsByTagName('head')[0];
            el = document.createElement('link');
            el.type = 'image/x-icon';
            el.rel = 'icon';
            el.href = url;
            el.id = id;
            head.appendChild(el);
        }

        // Update DOM if attributes are missing
        else if (!el.getAttribute('type') || !el.getAttribute('id')) {
            el.type = 'image/x-icon';
            el.id = id;
        }

        // Return DOM element
        return el;
    };

    // Draw icons in canvas to add badge counter
    const drawIcon = (src: string, count?: string, cb?: any) => {
        const img = document.createElement('img');
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            // Create canvas context from src
            const canvas: HTMLCanvasElement = document.createElement('canvas');
            canvas.width = favIconSize;
            canvas.height = favIconSize;
            const context: any = canvas.getContext('2d');
            context.clearRect(0, 0, img.width, img.height);
            context.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Draw notification
            if (count) {
                // Draw Circle
                context.beginPath();
                context.arc(canvas.width - favIconBadgeSize, favIconBadgeSize, favIconBadgeSize, 0, 2 * Math.PI);
                context.fillStyle = badgeColor;
                context.fill();

                // Draw Number
                context.font = `${favIconBadgeSize * 1.5}px "helvetica", sans-serif`;
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillStyle = '#FFFFFF';
                context.fillText(badgeCount, canvas.width - favIconBadgeSize, favIconBadgeSize);
            }

            // Returns base64
            cb(null, context.canvas.toDataURL());
        };
        img.src = src;
    };

    // Update favicon
    const updateFavIcon = () => {
        if (url !== currentUrl || (badgeEnabled && badgeCount !== currentCount)) {
            const el = searchFavIconDOM();
            let img: any = url;
            if (badgeEnabled) {
                drawIcon(img, badgeCount, (err: Error, data: string) => {
                    console.log(data);
                    currentUrl = data;
                    currentCount = badgeCount;
                    el.href = data;
                });
            } else {
                currentUrl = img;
                el.href = img;
            }
        }
    };

    // Set title
    useEffect(() => {
        document.title = `${title}`;
        updateFavIcon();
    });

    // Returns null
    return null;
};

export { FavIcon };
