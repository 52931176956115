
export const getLocalStorageState = (key: string) => {

    // Item can only be an object or null (no array... don't know why, but it arranges me now ^^)
    const item: string = window.localStorage.getItem(key) || 'null';

    // Parse and returns item or null
    try {
        return JSON.parse(item);
    }
    catch(err) {
        return null;
    }

}

export const setLocalStorageState = (key: string, item: any) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(item));
        return item;
    }
    catch(err) {
        return null;
    }
}

export const removeLocalStorageState = (key: string) => {
    try {
        window.localStorage.removeItem(key);
    }
    catch(err) {
        return null;
    }
}
