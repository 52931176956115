import { http } from '../http';
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { nz } from '../../helpers/numbers/numbers';
import { IAccountState } from '../reducers/account';
import { UiActionTypes } from '../reducers/ui';

export enum AccountActionTypes {
    REQUEST_ACCOUNT = 'REQUEST_ACCOUNT',
    REQUEST_ACCOUNT_SUCCESS = 'REQUEST_ACCOUNT_SUCCESS',
    REQUEST_ACCOUNT_ERROR = 'REQUEST_ACCOUNT_ERROR',
}

export interface IRequestAccountAction {
    type: AccountActionTypes.REQUEST_ACCOUNT;
    loading: boolean;
}

export interface IRequestAccountSuccessAction {
    [x: string]: any;
    type: AccountActionTypes.REQUEST_ACCOUNT_SUCCESS;
    loading: boolean;
    accounts: IAccountState['accounts'];
}

export interface IRequestAccountErrorAction {
    type: AccountActionTypes.REQUEST_ACCOUNT_ERROR;
    errorMessage: string;
}
export type AccountActions = IRequestAccountAction | IRequestAccountSuccessAction | IRequestAccountErrorAction;

/*<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const loadAccountAction: ActionCreator<ThunkAction<Promise<any>, IAccountState, null, IRequestAccountAction>> = (force: boolean = false) => {
    return async (dispatch: Dispatch, getState) => {
        // Get state
        try {
            const { account, ui }: any = getState();
            const { lastUpdate: accountLastUpdate } = account;
            const { lastUpdateDelay, minBalance } = ui;
            const deltaUpdate = Date.now() - nz(accountLastUpdate, 0);
            // console.log('lastUpdate', account, lastUpdate, deltaUpdate)

            // Skip if lastupdate is from seconds ago
            if ((deltaUpdate < lastUpdateDelay && !force) || account.loading) {
                return;
            }

            // Set account as loading
            dispatch({ type: AccountActionTypes.REQUEST_ACCOUNT, loading: true });

            // Build request and submit to API
            const params = { 'min-balance': minBalance };
            const uri = `/api/accounts`;
            const { data } = await http.get(uri, { params });
            const { accounts, allocations, repartitions, tokens, lastUpdate } = data;

            // Filter low balance allocations
            const minBalanceQuote = nz(parseFloat(minBalance), 0);
            allocations.items = allocations.items?.filter((item: any) => item.balance >= minBalanceQuote);

            // Dispatch an account store update
            dispatch({
                accounts,
                allocations,
                repartitions,
                tokens,
                lastUpdate,
                type: AccountActionTypes.REQUEST_ACCOUNT_SUCCESS,
                loading: false,
            });

            // App is ready
            dispatch({ type: UiActionTypes.UI_READY });
        } catch (err: any) {
            // Manage API errors
            console.error('AccountActions', err);
            dispatch({ type: AccountActionTypes.REQUEST_ACCOUNT_ERROR, errorMessage: err?.message });
        }
    };
};
