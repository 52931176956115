import { addMinutes } from 'date-fns';
import { getVariation } from '../numbers/numbers';

interface getAnalyticsPnlResponse {
    balance: number;
    pnl: number;
    currentDate: Date;
    compareDate: Date;
}

export const getAnalyticsPnl = (analytics: [any], days: number = 1, fromStartOfDay: boolean = true): getAnalyticsPnlResponse | undefined => {
    const _analytics = [...(analytics || [])].sort((a, b) => (a.at > b.at ? -1 : 1));
    const lastAnalytics = _analytics[0];
    const startOfDayAnalytics = _analytics?.find((analytic: any) => {
        const _date = new Date(analytic.at).valueOf();
        if (fromStartOfDay) {
            return _date <= new Date().setUTCHours(0, 0, 0);
        } else {
            return _date <= addMinutes(new Date(), -60 * 24).valueOf();
        }
    });
    if (!lastAnalytics || !startOfDayAnalytics) return undefined;
    return {
        balance: lastAnalytics?.balance,
        pnl: getVariation(startOfDayAnalytics?.balance, lastAnalytics?.balance),
        currentDate: new Date(lastAnalytics?.at),
        compareDate: new Date(startOfDayAnalytics?.at),
    };
};
