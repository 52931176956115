import { Reducer } from "react";
import { AnalyticActionTypes, AnalyticActions } from "../actions/analyticActions";

// Initial state
export interface IAnalyticActionState {
    loading?: boolean;
    analytics?: Array<any>;
    lastUpdate: number;
    lastAnalyticsUpdate: number;
}
const initialAnalyticState: IAnalyticActionState = {
    loading: false,
    analytics: undefined,
    lastUpdate: 0,
    lastAnalyticsUpdate: 0,
}

// Expose reducer
export const analyticReducer: Reducer<IAnalyticActionState, AnalyticActions> = (state = initialAnalyticState, action) => {

    switch (action.type) {

        // Bind update cycles
        case AnalyticActionTypes.REQUEST_ANALYTIC: {
            return {
                ...state,
                loading: action.loading
            };
        }

        // Update store
        case AnalyticActionTypes.REQUEST_ANALYTIC_SUCCESS: {
            console.log('analyticReducer REQUEST_ANALYTIC_SUCCESS', state, action)
            return {
                ...state,
                loading: action.loading,
                analytics: action.analytics,
                lastUpdate: action.lastUpdate,
                lastAnalyticsUpdate: action.lastAnalyticsUpdate,
                errorMessage: '',
            };
        }

        // Manage errors
        case AnalyticActionTypes.REQUEST_ANALYTIC_ERROR: {
            console.log('analyticReducer REQUEST_ANALYTIC_ERROR', state, action)
            return {
                ...state,
                loading: false,
                errorMessage: action.errorMessage,
            }
        }

        // No action
        default:
            return state;

    }

}
