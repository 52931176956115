import axios from 'axios';
import qs from 'qs';

import { getLocalStorageState } from '../helpers/localStorage/localStorage';

const { REACT_APP_API_HOST: baseURL } = process.env;
const url = baseURL || `${window.location.protocol}//${window.location.hostname}${window.location.port === '80' ? '' : `:${window.location.port}`}`;
console.log('API Url', url);
const instance = axios.create({
    baseURL: `${url}`,
    timeout: 30000,
    responseType: 'json',
    paramsSerializer: function (params) {
        return qs.stringify(params, { indices: false });
    },
});

// request header
instance.interceptors.request.use(
    config => {
        const { address } = getLocalStorageState('walletSignature') || { address: null, signature: null };
        const { quoteCurrency } = getLocalStorageState('ui');
        config.headers = { 'X-WALLET-ADDRESS': address };
        config.params = config.params || {};
        config.params['quote-currency'] = quoteCurrency;
        config.params['t'] = Date.now();
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

export const http = instance;
