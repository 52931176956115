import { useCallback, useEffect, useState } from "react";
import useLocalStorage, { deleteFromStorage, writeStorage } from "@rehooks/local-storage";
import { SiweMessage } from "siwe";
import { useAccount, useDisconnect, useNetwork, useSignMessage } from "wagmi";
import { http } from "../store/http";

const getSiweMessage = async (address: string, chainId: number): Promise<string> => {
    const nonceReq = await http.get<string>("/api/auth/nonce");
    const message = new SiweMessage({
        domain: window.location.host,
        address,
        nonce: nonceReq.data,
        statement: `Sign in with Web3 Wallet to ${process.env.REACT_APP_NAME}.`,
        uri: window.location.origin,
        version: "1",
        chainId,
        expirationTime: new Date(Date.now() + 7 * 24 * 3600 * 1000).toISOString(),
    });
    return message.prepareMessage();
};

export { useAccount, useDisconnect, useNetwork, useSignMessage };

export const useWalletConnect = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState<Error | undefined | null>();
    const [walletSignature] = useLocalStorage<{ address: string; signature: string }>("walletSignature");
    const { address, isConnected: isWalletConnected, isConnecting: isWalletConnecting } = useAccount();
    const { chain } = useNetwork();

    const { error: signMessageError, signMessageAsync } = useSignMessage({ message: "" });
    const { disconnect } = useDisconnect();

    // DISCONNECT FROM WALLET
    const signOut = useCallback(async () => {
        if (isWalletConnected || isWalletConnecting) {
            await disconnect();
        }
        deleteFromStorage("walletSignature");
        await http.get<string>("/api/auth/disconnect");
    }, [isWalletConnected, isWalletConnecting, disconnect]);

    // REGISTER TO API AND STORE SIGNATURE
    const signIn = useCallback(async () => {
        setErrorMessage(undefined);

        try {
            // Check condtions or throw
            if (!address) {
                throw new Error("signInWithEthereum: Address is missing");
            }

            if (!chain?.id) {
                throw new Error("signInWithEthereum: ChainId is missing");
            }

            // Build message to sign
            const message = await getSiweMessage(address, chain?.id);

            // Ask Web3 provider to sign message
            const signature = await signMessageAsync({ message });
            if (!signature) {
                throw new Error("AUTH_SIGNATURE_REJECTED", { cause: "REJECTED" });
            }

            // Check identity registering
            await http.post("/api/auth/verify", {
                message,
                signature,
            });

            // User is authenticated
            if (address && signature) {
                writeStorage("walletSignature", { address: address, signature: signature });
            }

            // Error while authenticating user
            else {
                throw new Error("SignIn has failed");
            }
        } catch (err) {
            console.error({ err, signMessageError });
            signOut();
        }
    }, [address, chain?.id, signOut, signMessageAsync, signMessageError]);

    // Authenticated state
    useEffect(() => {
        setIsAuthenticated(!!address && !!walletSignature);
    }, [address, walletSignature]);

    // Sign message error
    useEffect(() => {
        setErrorMessage(signMessageError);
    }, [signMessageError]);

    return {
        address,
        chainId: chain?.id,
        signIn,
        signOut,
        isAuthenticated,
        walletSignature,
        errorMessage,
    };
};
