import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Load reducers
import { networkReducer } from './reducers/network';
import { uiReducer } from './reducers/ui';
import { providerReducer } from './reducers/provider';
import { accountReducer } from './reducers/account';
import { analyticReducer } from './reducers/analytic';

// Define store
export const store = configureStore({
    reducer: combineReducers({
        network: networkReducer,
        provider: providerReducer,
        account: accountReducer,
        ui: uiReducer,
        analytic: analyticReducer,
    }),
    devTools: true,
});

// Hooks
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
