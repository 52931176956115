import { Reducer } from 'redux';
import { AccountActionTypes, AccountActions } from '../actions/accountActions';

export interface IAccountState {
    loading: boolean;
    errorMessage: string;
    lastUpdate: number;
    accounts: {
        changes: {
            balances: number;
            priceVariation: number;
            balanceVariation: number;
        };
        items: IAccount[];
        total: number;
    };
    allocations: IAccountAllocation;
    repartitions: {
        items: IAccountRepartitionItem[];
        total: number;
    };
    tokens: IToken[];
}

const initialAccountState: IAccountState = {
    loading: false,
    errorMessage: '',
    lastUpdate: 0,
    accounts: {
        changes: {
            balances: 0,
            priceVariation: 0,
            balanceVariation: 0,
        },
        items: [],
        total: 0,
    },
    allocations: {
        items: [],
        total: 0,
    },
    repartitions: {
        items: [],
        total: 0,
    },
    tokens: [],
};

export const accountReducer: Reducer<IAccountState, AccountActions> = (state = initialAccountState, action) => {
    switch (action.type) {
        case AccountActionTypes.REQUEST_ACCOUNT: {
            console.log('accountReducer', state, action);
            return {
                ...state,
                loading: action.loading,
            };
        }
        case AccountActionTypes.REQUEST_ACCOUNT_SUCCESS: {
            console.log('accountReducer', state, action);
            return {
                ...state,
                loading: action.loading,
                accounts: action.accounts,
                allocations: action.allocations,
                repartitions: action.repartitions,
                tokens: action.tokens,
                avgBuyPrice: action.avgBuyPrice,
                lastUpdate: action.lastUpdate,
                errorMessage: '',
            };
        }
        case AccountActionTypes.REQUEST_ACCOUNT_ERROR: {
            console.log('accountReducer', state, action);
            return {
                ...state,
                loading: false,
                errorMessage: action.errorMessage,
            };
        }
        default:
            return state;
    }
};

// export const fetchAccount = createAsyncThunk('/api/accounts', async (input: { force?: boolean }, thunkApi) => {
//     return http.get<IAccountState>('/api/accounts');
// });

// const accountSlice = createSlice({
//     name: 'account',
//     initialState: {
//         ...initialAccountState,
//     },
//     reducers: {},
//     extraReducers: builder => {
//         builder.addCase(fetchAccount.pending, state => {
//             state.loading = true;
//         });
//         builder.addCase(fetchAccount.fulfilled, (state, action) => {
//             state.loading = false;
//             console.log('fetchAccount.fulfilled', state, action);
//         });
//         builder.addCase(fetchAccount.rejected, (state, action) => {
//             state.loading = false;
//         });
//     },
// });

// export const accountReducer = accountSlice.reducer;
