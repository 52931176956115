import React from "react";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./App";
import { store } from "./store/store";
import { createRoot } from "react-dom/client";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { mainnet, polygon, bsc } from "wagmi/chains";

const projectId = process.env.REACT_APP_WALLET_CONNECT_ID as string;

// Supported chains
const chains = [mainnet, polygon, bsc];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ chains, projectId }),
    publicClient,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container);

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <WagmiConfig config={wagmiConfig}>
                    <App />
                </WagmiConfig>
                <Web3Modal
                    projectId={projectId}
                    ethereumClient={ethereumClient}
                />
            </Provider>
        </React.StrictMode>,
    );
}
