/**
 * Round a string or a number to specified decimals.
 *
 * @exports helpers/numbers/roundTo
 * @param {number|string} n - The number to round to.
 * @param {number} decimals - The decimals to keep.
 * @returns {number} - The parsed number.
 * @example
 * roundTo('238.98', 2);
 */
export function roundTo(n: string | number, decimals = 2) {
    return nz(parseFloat(parseFloat((n || '').toString()).toFixed(decimals)), 0);
}

/**
 * Round a string or a number to specified decimals and returns a string.
 *
 * @exports helpers/numbers/roundToFixed
 * @param {number|string} n - The number to round to.
 * @param {number} decimals - The decimals to keep.
 * @returns {string} - The parsed number in string.
 * @example
 * roundToFixed('238.98', 2);
 */
export function roundToFixed(n: string | number | undefined, decimals = 2) {
    return roundTo(nz(parseFloat((n || '').toString()), 0), decimals).toFixed(decimals);
}

/**
 * Calculate the variation in percents between two numbers.
 *
 * @exports helpers/numbers/getVariation
 * @param {number} past - The past value to compare.
 * @param {number} now - The actual value to compare.
 * @returns {number} - The variation.
 * @example
 * getVariation(1, 2);
 */
export function getVariation(past: number | string, now: number | string) {
    past = parseFloat(past.toString());
    now = parseFloat(now.toString());
    return ((now - past) * 100) / Math.abs(past);
}

/**
 * Non-Nan : Returns second number if first is NaN or undefined.
 *
 * @exports helpers/numbers/nz
 * @param {number} x - The number to test non NaN.
 * @param {number} y - The number to use if fails.
 * @returns {number} - The best of the two worlds.
 * @example
 * nz(NaN, 2); => 2
 */
export function nz(x: number | undefined, y: number) {
    return x === undefined || isNaN(x) ? y : x;
}

/**
 * Use Intl to format a price in the user locale.
 *
 * @exports helpers/numbers/formatPrice
 * @param {number|string} n - The number to round to.
 * @param {number} decimals - The decimals to keep.
 * @param {string | undefined} locale - The locale to use for format
 * @returns {string} - The parsed number in string.
 * @example
 * formatPrice('238.98', 2);
 */
export function formatPrice(n: string | number | undefined, decimals = 2, locale: string | undefined = 'en') {
    const num: number = Number((n || 0).toString());

    // Split to parts to filter currency
    const parts = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: decimals,
        minimumFractionDigits: Math.min(decimals, 2),
        currencyDisplay: 'symbol',
    }).formatToParts(num);

    // Keep all parts except currency
    return parts
        .map((k: any) => {
            return ['litteral', 'currency'].indexOf(k.type) >= 0 ? '' : k.value;
        })
        .join('');
}

/**
 * Sum all values in an array
 *
 * @exports helpers/numbers/sumArray
 * @param {number[]} n - The array to sum.
 * @returns {number} - The sum of all values.
 * @example
 * formatPrice('238.98', 2);
 */
export function sumArray(n: number[]) {
    return n.reduce((a: number, b: number) => a + b, 0) || 0;
}
