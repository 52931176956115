import { Alert, Box, Button, styled, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { http } from '../../store/http';
import { Web3Button } from '@web3modal/react';
import { useWalletConnect, useAccount } from '../../hooks/useWalletConnect';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import { Container } from '@mui/system';
import { LOGO_STATUS, StyledLogoAnimated } from '../Logo/StyleLogoAnimated';

const StyledFullPage = styled(Box)(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '-3.25rem',
    };
});

const AuthProvider = ({ children }: { children: JSX.Element[] }) => {
    const { address, isConnected, isDisconnected } = useAccount();
    // const provider = useProvider();
    // const { chain } = useNetwork();
    const { signIn, signOut, isAuthenticated, walletSignature, errorMessage } = useWalletConnect();

    // Intercept 401 status to logout user
    useEffect(() => {
        const resInterceptor = (response: AxiosResponse) => response;
        const errInterceptor = (error: any) => {
            if (error.response.status === 401 && isAuthenticated) {
                signOut();
            }
            return Promise.reject(error);
        };

        const interceptor = http.interceptors.response.use(resInterceptor, errInterceptor);

        return () => http.interceptors.response.eject(interceptor);
    }, [signOut, isAuthenticated]);

    const DebugWeb = () => {
        return <></>;
        // return (
        //     <Box>
        //         {JSON.stringify({
        //             chainId: chain?.id,
        //             address,
        //             isAuthenticated,
        //             isConnected,
        //             isConnecting,
        //             isDisconnected,
        //             walletSignature,
        //             provider,
        //         })}
        //     </Box>
        // );
    };

    if (isConnected && walletSignature?.address === address) {
        return (
            <Box>
                <DebugWeb />
                {children}
            </Box>
        );
    }

    return (
        <StyledFullPage>
            <Container maxWidth="lg" sx={{ justifyContent: 'center' }}>
                <DebugWeb />

                <Box sx={{ marginBottom: '2rem' }}>
                    <StyledLogoAnimated size={128} status={isConnected ? LOGO_STATUS.ACTIVE : LOGO_STATUS.DISCONNECTED} />
                </Box>

                {errorMessage && (
                    <Alert severity="error" sx={{ margin: '1rem auto 1rem auto' }}>
                        {errorMessage?.message}
                    </Alert>
                )}

                {isConnected && (
                    <>
                        <Box sx={{ marginBottom: '1rem', textAlign: 'center' }}>
                            <Button variant="contained" color="primary" onClick={signIn} size="large" startIcon={<SecurityOutlinedIcon />}>
                                <Typography>Sign Auth Message</Typography>
                            </Button>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button variant="outlined" color="secondary" onClick={signOut} size="small">
                                <Typography>Disconnect</Typography>
                            </Button>
                        </Box>
                    </>
                )}

                {isDisconnected && (
                    <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Web3Button />
                    </Box>
                )}
            </Container>
        </StyledFullPage>
    );
};

export default AuthProvider;
