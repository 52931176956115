import { http } from '../http';
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { nz } from '../../helpers/numbers/numbers';
import { IAnalyticActionState } from '../reducers/analytic';
import { parseISO } from 'date-fns';

export enum AnalyticActionTypes {
    REQUEST_ANALYTIC = 'REQUEST_ANALYTIC',
    REQUEST_ANALYTIC_SUCCESS = 'REQUEST_ANALYTIC_SUCCESS',
    REQUEST_ANALYTIC_ERROR = 'REQUEST_ANALYTIC_ERROR',
}

export interface IRequestAnalyticAction {
    type: AnalyticActionTypes.REQUEST_ANALYTIC;
    loading: boolean;
}

export interface IRequestAnalyticSuccessAction {
    [x: string]: any;
    type: AnalyticActionTypes.REQUEST_ANALYTIC_SUCCESS;
    loading: boolean;
    accounts: Array<IAccount>;
}

export interface IRequestAnalyticErrorAction {
    type: AnalyticActionTypes.REQUEST_ANALYTIC_ERROR;
    errorMessage: string;
}
export type AnalyticActions = IRequestAnalyticAction | IRequestAnalyticSuccessAction | IRequestAnalyticErrorAction;

/*<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const loadAnalyticAction: ActionCreator<ThunkAction<Promise<any>, IAnalyticActionState, null, IRequestAnalyticAction>> = (force: boolean = false) => {
    return async (dispatch: Dispatch, getState) => {
        try {
            // Get state
            const { analytic, ui }: any = getState();
            const { lastUpdateDelay } = ui;
            const { lastUpdate: accountLastUpdate } = analytic;
            const deltaUpdate = Date.now() - nz(accountLastUpdate, 0);

            // Skip if lastupdate is from seconds ago
            if ((deltaUpdate < lastUpdateDelay && !force) || analytic.loading) {
                return;
            }

            // Set account as loading
            dispatch({ type: AnalyticActionTypes.REQUEST_ANALYTIC, loading: true });

            // Build request and submit to API
            const params = {};
            const uri = `/api/points`;
            const { data } = await http.get(uri, { params });
            const { analytics, lastUpdate, lastAnalyticsUpdate } = data;

            // Get historics for PNL calculation
            const historics = [...analytics].reverse();
            const lastAnalytics = historics[0];
            const dayStartAnalytics = historics.find(n => n.at);

            // Debug
            console.log('analytics', historics, lastAnalyticsUpdate, lastAnalytics, dayStartAnalytics, parseISO(dayStartAnalytics.at), force);

            // Dispatch an account store update
            dispatch({ analytics, lastUpdate, lastAnalyticsUpdate, type: AnalyticActionTypes.REQUEST_ANALYTIC_SUCCESS, loading: false });
        } catch (err: any) {
            // Manage API errors
            console.error('AnalyticActions', err);
            dispatch({ type: AnalyticActionTypes.REQUEST_ANALYTIC_ERROR, errorMessage: err?.message });
        }
    };
};
