import { getLocalStorageState, removeLocalStorageState, setLocalStorageState } from '../../helpers/localStorage/localStorage';
import { nz } from '../../helpers/numbers/numbers';
import { pick } from '../../helpers/utils/pick';

// Actions for reducer
export enum UiActionTypes {
    UI_TOGGLE_MENU = 'UI_TOGGLE_MENU',
    UI_READY = 'UI_READY',
    UI_THEME_CHANGE = 'UI_THEME_CHANGE',
    UI_COLOR_NEGATIVE = 'UI_COLOR_NEGATIVE',
    UI_COLOR_POSITIVE = 'UI_COLOR_POSITIVE',
    UI_RESET = 'UI_RESET',
    UI_MIN_BALANCE = 'UI_MIN_BALANCE',
    UI_HIDE_AMOUNTS = 'UI_HIDE_AMOUNTS',
}

// Initial state of the wallet
export interface IUiState {
    title: string;
    loading: boolean;
    menuExpanded: boolean;
    theme: string;
    ready: boolean;
    themeLightGreyLight: string;
    themeDarkGreyLight: string;
    themeDarkGreyStrong: string;
    colorPositive: string;
    colorNegative: string;
    lastUpdateDelay: number;
    hideAmounts: boolean;
    minBalance: number;
    quoteCurrency: string;
    favIcon: string;
}

// Build defaults
const userDefaults: any = getLocalStorageState('ui') || { quoteCurrency: 'USDT', minBalance: 10 };
const useDefaultsColorPositive = '#00bcd4';
const useDefaultsColorNegative = '#ffaa55';

// Initial state
const initialState: IUiState = {
    title: 'CAEB Dashboard',
    loading: false,
    menuExpanded: false,
    theme: 'auto',
    ready: false,
    themeLightGreyLight: '#dadada',
    themeDarkGreyLight: '#546e7a',
    themeDarkGreyStrong: '#2a373d',
    colorPositive: userDefaults.colorPositive || useDefaultsColorPositive,
    colorNegative: userDefaults.colorNegative || useDefaultsColorNegative,
    lastUpdateDelay: 30 * 1000,
    hideAmounts: true,
    minBalance: nz(userDefaults.minBalance, 0),
    quoteCurrency: userDefaults.quoteCurrency || 'USDT',
    favIcon: '/favicon.ico',
};

// Set local storage vars
const localStorageVars: any = ['colorPositive', 'colorNegative', 'minBalance', 'quoteCurrency', 'theme'];
setLocalStorageState('ui', pick(initialState, localStorageVars));

// Expose reducer
export function uiReducer(state = initialState, action: ReducerAction) {
    switch (action.type) {
        // UI is ready
        case UiActionTypes.UI_READY: {
            return {
                ...state,
                ready: true,
            };
        }

        // Toggle mobile menu
        case UiActionTypes.UI_TOGGLE_MENU: {
            return {
                ...state,
                menuExpanded: !state.menuExpanded,
            };
        }

        // Change theme
        case UiActionTypes.UI_THEME_CHANGE: {
            return {
                ...state,
                theme: action.payload.theme,
            };
        }

        // Change theme color
        case UiActionTypes.UI_COLOR_NEGATIVE: {
            const newState = {
                ...state,
                colorNegative: action.payload.color,
            };
            setLocalStorageState('ui', pick(newState, localStorageVars));
            return newState;
        }
        case UiActionTypes.UI_COLOR_POSITIVE: {
            const newState = {
                ...state,
                colorPositive: action.payload.color,
            };
            setLocalStorageState('ui', pick(newState, localStorageVars));
            return newState;
        }

        // Toggle hide amount
        case UiActionTypes.UI_HIDE_AMOUNTS: {
            const newState = {
                ...state,
                hideAmounts: !state.hideAmounts,
            };
            return newState;
        }

        // Toggle hide amount
        case UiActionTypes.UI_MIN_BALANCE: {
            const newState = {
                ...state,
                minBalance: action.payload.minBalance,
            };
            setLocalStorageState('ui', pick(newState, localStorageVars));
            return newState;
        }

        // Reset UI
        case UiActionTypes.UI_RESET: {
            const newState = {
                ...state,
                colorNegative: useDefaultsColorNegative,
                colorPositive: useDefaultsColorPositive,
                theme: 'auto',
                minBalance: 0,
            };
            removeLocalStorageState('ui');
            return newState;
        }

        // No action
        default:
            return state;
    }
}
