import { Component } from "react";
import './ContentLoader.scss';

class ContentLoader extends Component {
    render() {
        return (
            <div className="ContentLoader">
                <div className="hero is-fullheight">
                    <div className="logo animated"></div>
                    <p>loading....</p>
                </div>
                <div className="columns is-vcentered is-centered is-fullheight">
                    <div className="column">
                        <div className="logo animated"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export { ContentLoader }
