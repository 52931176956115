/**
 * Debounce a function with a delay.
 * @param {function} fn - The callback to execute after the end of the storm.
 * @param {number} delay - The delay to wait.
 * @returns {function} - The wrapped debounceed function.
 */

export function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
    let timeoutID: number = 0;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutID);
        timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
    } as F;
}
