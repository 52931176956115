// Initial state of the wallet
const initialState: StoreProviderState = {
    providers: [
        {
            id: 'binance',
            name: 'BINANCE',
            logoURI: '/assets/exchanges/binance.svg',
            fields: [
                {
                    label: 'API Key',
                    key: 'apiKey',
                    type: 'input',
                },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    type: 'input',
                },
            ],
        },
        {
            id: 'bsc',
            name: 'BSC',
            logoURI: '/assets/exchanges/bsc.svg',
            fields: [
                {
                    label: 'BSC Address',
                    key: 'address',
                    type: 'input',
                },
                {
                    label: 'API Key',
                    key: 'apiKey',
                    type: 'input',
                },
                {
                    label: 'Explorer API Key',
                    key: 'ethExplorerApiKey',
                    type: 'input',
                },
            ],
        },
        {
            id: 'kucoin',
            name: 'KUCOIN',
            logoURI: '/assets/exchanges/kucoin.svg',
            fields: [
                {
                    label: 'API Key',
                    key: 'apiKey',
                    type: 'input',
                },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    type: 'input',
                },
                {
                    label: 'API Pass Phrase',
                    key: 'apiPassPhrase',
                    type: 'input',
                },
                {
                    label: 'Subaccount ID',
                    key: 'subaccountID',
                    type: 'input',
                },
            ],
        },
        {
            id: 'ftm',
            name: 'FANTOM',
            logoURI: '/assets/exchanges/ftm.svg',
            fields: [
                {
                    label: 'FTM Address',
                    type: 'input',
                },
                {
                    label: 'API Key',
                    type: 'input',
                },
                {
                    label: 'Explorer API Key',
                    type: 'input',
                },
            ],
        },
        {
            id: 'elrond',
            name: 'ELROND',
            logoURI: '/assets/exchanges/elrond.svg',
            fields: [
                {
                    label: 'Elrond Address',
                    key: 'address',
                    type: 'input',
                },
            ],
        },
        {
            id: 'ftx',
            name: 'FTX',
            logoURI: '/assets/exchanges/ftx.svg',
            fields: [
                {
                    label: 'API Key',
                    key: 'apiKey',
                    type: 'input',
                },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    type: 'input',
                },
                {
                    label: 'Subaccount ID',
                    key: 'subaccountID',
                    type: 'input',
                },
            ],
        },
        {
            id: 'gateio',
            name: 'Gate.io',
            logoURI: '/assets/exchanges/gate.io.png',
            fields: [
                {
                    label: 'API Key',
                    key: 'apiKey',
                    type: 'input',
                },
                {
                    label: 'API Secret',
                    key: 'apiSecret',
                    type: 'input',
                },
                {
                    label: 'Subaccount ID',
                    key: 'subaccountID',
                    type: 'input',
                },
            ],
        },
        // {
        //     id: 'poloniex',
        //     name: 'POLONIEX',
        //     logoURI: '/assets/exchanges/poloniex.svg',
        // },
        {
            id: 'terra',
            name: 'TERRA',
            logoURI: '/assets/exchanges/terra.png',
            fields: [
                {
                    label: 'Terra Address',
                    key: 'address',
                    type: 'input',
                },
            ],
        },
    ],
};

// Expose reducer
export function providerReducer(state = initialState, action: ReducerAction) {
    switch (action.type) {
        // No action
        default:
            return state;
    }
}
