import { useCallback, useEffect, useState } from 'react';
import { getLocalStorageState, setLocalStorageState } from '../helpers/localStorage/localStorage';
import { debounce } from '../helpers/utils/debounce';
import { UiActionTypes } from '../store/reducers/ui';
import { store } from '../store/store';

export enum ThemeTypes {
    THEME_AUTO = 'theme-auto',
    THEME_LIGHT = 'theme-light',
    THEME_DARK = 'theme-dark',
}

export enum ThemeFavIcons {
    THEME_FAVICON_LIGHT = '/assets/favicon/favicon.ico',
    THEME_FAVICON_DARK = '/assets/favicon/favicon-dark.ico',
}

export const useDarkMode = () => {
    const [theme, setTheme] = useState(getLocalStorageState('theme'));
    const [mountedComponent, setMountedComponent] = useState(false);

    const setMode = useCallback((mode: string) => {
        const modes = [ThemeTypes.THEME_AUTO, ThemeTypes.THEME_DARK, ThemeTypes.THEME_LIGHT];
        console.log('setMode:', mode);
        document.documentElement.classList.remove(...modes.filter(k => k !== mode));
        document.documentElement.classList.add(mode);
        setLocalStorageState('theme', mode);
        setTheme(mode);
        changeFavIcon(mode);
        store.dispatch({ type: UiActionTypes.UI_THEME_CHANGE, payload: { theme: mode } });
    }, []);

    const themeToggler = () => {
        theme === ThemeTypes.THEME_LIGHT ? setMode(ThemeTypes.THEME_DARK) : setMode(ThemeTypes.THEME_LIGHT);
    };

    // Change favicon
    const changeFavIcon = (mode: string) => {
        const faviconEl: HTMLLinkElement | null = document.querySelector('link[rel="shortcut icon"]');
        if (faviconEl) {
            const favicon = mode === ThemeTypes.THEME_DARK ? ThemeFavIcons.THEME_FAVICON_DARK : ThemeFavIcons.THEME_FAVICON_LIGHT;
            faviconEl.href = `${favicon}?_=${Math.random()}`;
        }
    };

    // Listen for navigator state
    let navigatorPrefers = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? ThemeTypes.THEME_DARK : ThemeTypes.THEME_LIGHT;
    if (!window.matchMedia('(prefers-color-scheme: dark)').onchange) {
        const debouncedUpdated = debounce((e: any) => {
            navigatorPrefers = e.matches ? ThemeTypes.THEME_DARK : ThemeTypes.THEME_LIGHT;
            console.log('navigator theme changed: ', navigatorPrefers);
            setMode(navigatorPrefers);
        }, 300);
        window.matchMedia('(prefers-color-scheme: dark)').onchange = debouncedUpdated.bind(window.matchMedia('(prefers-color-scheme: dark)'));
    }

    // Hook
    useEffect(() => {
        const localTheme = getLocalStorageState('theme');
        console.log('localTheme', localTheme);
        setMode(navigatorPrefers);
        setMountedComponent(true);
    }, [navigatorPrefers, setMode]);

    return [theme, themeToggler, mountedComponent];
};
